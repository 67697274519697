var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export class BlockAutocomplete {
    constructor() {
        this.dropdown = null;
        // Initialize empty and fetch real data
        this.blockMaps = [];
        this.BLOCK_MAP_ENDPOINT = `${window.location.origin}/my/autocomplete/block-maps`;
        this.pollTimer = null;
        this.lastKnownLuids = new Set();
        // Add debouncing for search to improve performance
        this.debounceTimer = null;
        this.DEBOUNCE_DELAY = 150; // ms
        // Add memoization for filtered matches
        this.lastSearchTerm = "";
        this.cachedMatches = [];
        this.handleClickOutside = (e) => {
            const target = e.target;
            if (!target.closest(".block-autocomplete-dropdown")) {
                this.hideDropdown();
            }
        };
        // Bind the click handler
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.state = {
            isActive: false,
            searchTerm: "",
            selectedIndex: 0,
            matches: [],
        };
        this.initializeData();
        this.initializeListeners();
        this.startPolling();
    }
    initializeListeners() {
        document.addEventListener("input", (e) => {
            if (e.target instanceof HTMLElement) {
                this.handleInput(e);
            }
        });
        document.addEventListener("keydown", (e) => {
            if (e.target instanceof HTMLElement) {
                this.handleKeydown(e);
            }
        });
        // Use the bound method
        document.addEventListener("click", this.handleClickOutside);
    }
    isValidEditor(element) {
        return (element.getAttribute("contenteditable") === "true" &&
            element.getAttribute("data-type") === "bmd-editor");
    }
    getCaretPosition(element) {
        const selection = window.getSelection();
        if (!selection || !selection.rangeCount)
            return -1;
        const range = selection.getRangeAt(0);
        const preCaretRange = range.cloneRange();
        preCaretRange.selectNodeContents(element);
        preCaretRange.setEnd(range.endContainer, range.endOffset);
        return preCaretRange.toString().length;
    }
    getCurrentWord(text, position) {
        const beforeCaret = text.slice(0, position);
        const linkRegex = /\/link([^/]*?)$/;
        const match = beforeCaret.match(linkRegex);
        return match ? `/link${match[1]}` : "";
    }
    sanitizeSearchTerm(term) {
        return term.replace(/[<>]/g, "").trim().toLowerCase();
    }
    handleInput(event) {
        const target = event.target;
        if (!this.isValidEditor(target))
            return;
        const position = this.getCaretPosition(target);
        const currentWord = this.getCurrentWord(target.textContent || "", position);
        if (!currentWord.startsWith("/link")) {
            this.hideDropdown();
            return;
        }
        const searchTerm = this.sanitizeSearchTerm(currentWord.slice(5));
        this.updateState(searchTerm, target);
    }
    handleKeydown(event) {
        if (!this.state.isActive)
            return;
        try {
            switch (event.key) {
                case "ArrowUp":
                    event.preventDefault();
                    this.state.selectedIndex = Math.max(0, this.state.selectedIndex - 1);
                    this.updateDropdown();
                    break;
                case "ArrowDown":
                    event.preventDefault();
                    this.state.selectedIndex = Math.min(this.state.matches.length - 1, this.state.selectedIndex + 1);
                    this.updateDropdown();
                    break;
                case "Enter":
                    event.preventDefault();
                    if (this.state.matches.length) {
                        const target = event.target;
                        if (this.isValidEditor(target)) {
                            this.insertBlock(target);
                        }
                    }
                    else if (this.state.searchTerm.trim()) {
                        const target = event.target;
                        if (this.isValidEditor(target)) {
                            this.insertText(target, `[[${this.state.searchTerm}]]`);
                        }
                    }
                    break;
                case "Escape":
                    event.preventDefault();
                    this.hideDropdown();
                    break;
                case "Tab":
                    // Prevent tab from moving focus if dropdown is active
                    if (this.state.isActive && this.state.matches.length) {
                        event.preventDefault();
                        const target = event.target;
                        if (this.isValidEditor(target)) {
                            this.insertBlock(target);
                        }
                    }
                    break;
            }
        }
        catch (error) {
            console.error("Error handling keydown:", error);
            // Ensure dropdown is hidden on error
            this.hideDropdown();
        }
    }
    createDropdown() {
        const dropdown = document.createElement("div");
        dropdown.className = "block-autocomplete-dropdown";
        dropdown.style.cssText = BlockAutocomplete.STYLES.DROPDOWN;
        return dropdown;
    }
    showDropdown(editor) {
        if (!this.dropdown) {
            this.dropdown = this.createDropdown();
            document.body.appendChild(this.dropdown);
        }
        const selection = window.getSelection();
        if (!(selection === null || selection === void 0 ? void 0 : selection.rangeCount))
            return;
        const range = selection.getRangeAt(0);
        const rect = range.getBoundingClientRect();
        // Ensure dropdown stays within viewport
        const viewportHeight = window.innerHeight;
        const dropdownHeight = 200; // from STYLES.DROPDOWN max-height
        let top = rect.bottom + window.scrollY;
        if (top + dropdownHeight > viewportHeight) {
            top = rect.top - dropdownHeight + window.scrollY;
        }
        this.dropdown.style.top = `${Math.max(0, top)}px`;
        this.dropdown.style.left = `${Math.max(0, rect.left + window.scrollX)}px`;
        this.updateDropdown();
    }
    updateDropdown() {
        const dropdown = this.dropdown;
        if (!dropdown)
            return;
        // Clear existing content
        while (dropdown.firstChild) {
            dropdown.removeChild(dropdown.firstChild);
        }
        const { matches, selectedIndex, searchTerm } = this.state;
        // Add match count or create new block suggestion
        const matchCount = document.createElement("div");
        matchCount.style.cssText = BlockAutocomplete.STYLES.MATCH_COUNT;
        if (matches.length === 0 && searchTerm.trim()) {
            matchCount.textContent = `Create [[${searchTerm}]]`;
            matchCount.style.cursor = "pointer";
            matchCount.addEventListener("click", () => {
                const editor = document.activeElement;
                if (this.isValidEditor(editor)) {
                    const blockRef = `[[${searchTerm}]]`;
                    this.insertText(editor, blockRef);
                }
            });
        }
        else {
            matchCount.textContent = `Found ${matches.length} match${matches.length !== 1 ? "es" : ""}`;
        }
        dropdown.appendChild(matchCount);
        // Add matches
        matches.forEach((block, index) => {
            const item = document.createElement("div");
            item.className = "block-autocomplete-item";
            item.style.cssText = `${BlockAutocomplete.STYLES.ITEM}
        ${index === selectedIndex ? BlockAutocomplete.STYLES.SELECTED_ITEM : ""}`;
            item.dataset.index = index.toString();
            // Add link icon
            const icon = document.createElement("span");
            icon.style.marginRight = "8px";
            icon.textContent = "🔗";
            item.appendChild(icon);
            // Add highlighted title with truncation
            const titleContainer = document.createElement("span");
            const truncatedSlug = this.truncateSlug(block.slug);
            titleContainer.innerHTML = this.highlightMatchedText(truncatedSlug, searchTerm);
            // Add title attribute to show full text on hover if truncated
            if (block.slug.length > BlockAutocomplete.MAX_SLUG_DISPLAY_LENGTH) {
                item.title = block.slug;
            }
            item.appendChild(titleContainer);
            // Add event listeners
            item.addEventListener("click", () => {
                this.state.selectedIndex = index;
                this.insertBlock(document.activeElement);
            });
            item.addEventListener("mouseover", () => {
                this.state.selectedIndex = index;
                this.updateDropdown();
            });
            dropdown.appendChild(item);
            // If this is the selected item, scroll it into view
            if (index === selectedIndex) {
                // Use requestAnimationFrame to ensure the scroll happens after the item is rendered
                requestAnimationFrame(() => {
                    item.scrollIntoView({ block: "nearest", behavior: "smooth" });
                });
            }
        });
    }
    hideDropdown() {
        if (this.dropdown) {
            this.dropdown.remove();
            this.dropdown = null;
        }
        this.state.isActive = false;
    }
    insertBlock(editor) {
        if (!this.isValidEditor(editor))
            return;
        const selection = window.getSelection();
        const selectedBlock = this.state.matches[this.state.selectedIndex];
        if (!(selection === null || selection === void 0 ? void 0 : selection.rangeCount) || !selectedBlock) {
            this.hideDropdown();
            return;
        }
        try {
            const range = selection.getRangeAt(0);
            const text = range.startContainer.textContent || "";
            const linkStart = text.lastIndexOf("/link", range.startOffset);
            if (linkStart === -1) {
                this.hideDropdown();
                return;
            }
            const newRange = document.createRange();
            newRange.setStart(range.startContainer, linkStart);
            newRange.setEnd(range.startContainer, range.startOffset);
            // Add a space after the block reference
            const blockRef = `[${selectedBlock.slug}](@${selectedBlock.luid}) `;
            // Create a new text node with the block reference
            const textNode = document.createTextNode(blockRef);
            newRange.deleteContents();
            newRange.insertNode(textNode);
            // Create and set a new range at the end of the inserted text
            const endRange = document.createRange();
            endRange.setStart(textNode, blockRef.length);
            endRange.setEnd(textNode, blockRef.length);
            // Clear existing selection and set the new range
            selection.removeAllRanges();
            selection.addRange(endRange);
            // Ensure the cursor is visible and in focus
            editor.focus();
        }
        catch (error) {
            console.error("Error inserting block:", error);
        }
        finally {
            this.hideDropdown();
        }
    }
    highlightMatchedText(title, searchTerm) {
        if (!searchTerm)
            return title;
        const terms = searchTerm.split(/\s+/).filter(Boolean);
        const fragment = document.createDocumentFragment();
        let currentIndex = 0;
        const lowerTitle = title.toLowerCase();
        terms.forEach((term) => {
            const termLower = term.toLowerCase();
            let matchIndex = lowerTitle.indexOf(termLower, currentIndex);
            while (matchIndex !== -1) {
                // Add text before match
                if (matchIndex > currentIndex) {
                    const beforeText = document.createTextNode(title.substring(currentIndex, matchIndex));
                    fragment.appendChild(beforeText);
                }
                // Add highlighted match
                const highlight = document.createElement("span");
                highlight.style.cssText = BlockAutocomplete.STYLES.HIGHLIGHT;
                highlight.textContent = title.substr(matchIndex, term.length);
                fragment.appendChild(highlight);
                currentIndex = matchIndex + term.length;
                matchIndex = lowerTitle.indexOf(termLower, currentIndex);
            }
        });
        // Add remaining text
        if (currentIndex < title.length) {
            const remainingText = document.createTextNode(title.substring(currentIndex));
            fragment.appendChild(remainingText);
        }
        const container = document.createElement("div");
        container.appendChild(fragment);
        return container.innerHTML;
    }
    getFilteredMatches(searchTerm) {
        if (searchTerm === this.lastSearchTerm) {
            return this.cachedMatches;
        }
        this.lastSearchTerm = searchTerm;
        this.cachedMatches = this.blockMaps.filter((block) => searchTerm
            .split(/\s+/)
            .every((term) => block.slug.toLowerCase().includes(term.toLowerCase())));
        return this.cachedMatches;
    }
    updateState(searchTerm, editor) {
        this.state = Object.assign(Object.assign({}, this.state), { isActive: true, searchTerm, selectedIndex: 0, matches: this.getFilteredMatches(searchTerm) });
        this.showDropdown(editor);
    }
    initializeData() {
        return __awaiter(this, void 0, void 0, function* () {
            console.log("Initializing block autocomplete data..."); // Debug log
            try {
                yield this.fetchBlockMaps();
            }
            catch (error) {
                console.error("Failed to initialize block maps:", error);
            }
        });
    }
    fetchBlockMaps() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const response = yield fetch(this.BLOCK_MAP_ENDPOINT, {
                    credentials: "include",
                    headers: {
                        Accept: "application/json",
                    },
                });
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = (yield response.json());
                // Initialize our blockMaps and lastKnownLuids
                this.blockMaps = data;
                this.lastKnownLuids = new Set(data.map((block) => block.luid));
                console.log(`Initially loaded ${data.length} blocks`);
            }
            catch (error) {
                console.error("Error in fetchBlockMaps:", error);
                this.blockMaps = [];
                this.lastKnownLuids = new Set();
            }
        });
    }
    startPolling() {
        // Clear any existing timer
        if (this.pollTimer) {
            window.clearInterval(this.pollTimer);
        }
        // Start new polling interval
        this.pollTimer = window.setInterval(() => {
            this.pollForNewBlocks();
        }, BlockAutocomplete.POLL_INTERVAL);
    }
    pollForNewBlocks() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const response = yield fetch(this.BLOCK_MAP_ENDPOINT, {
                    credentials: "include",
                    headers: {
                        Accept: "application/json",
                    },
                });
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const newData = (yield response.json());
                // Filter out blocks we already have
                const newBlocks = newData.filter((block) => !this.lastKnownLuids.has(block.luid));
                if (newBlocks.length > 0) {
                    // Add new blocks to our list
                    this.blockMaps = [...this.blockMaps, ...newBlocks];
                    // Update our set of known LUIDs
                    newBlocks.forEach((block) => this.lastKnownLuids.add(block.luid));
                    console.log(`Added ${newBlocks.length} new blocks`);
                }
            }
            catch (error) {
                console.error("Error polling for new blocks:", error);
            }
        });
    }
    static getInstance() {
        if (!BlockAutocomplete.instance) {
            BlockAutocomplete.instance = new BlockAutocomplete();
        }
        return BlockAutocomplete.instance;
    }
    // Method to manually refresh and restart polling
    refreshBlockMaps() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                // Stop current polling
                if (this.pollTimer) {
                    window.clearInterval(this.pollTimer);
                    this.pollTimer = null;
                }
                // Fetch fresh data
                yield this.fetchBlockMaps();
                // Restart polling
                this.startPolling();
                return true;
            }
            catch (error) {
                console.error("Failed to refresh block maps:", error);
                return false;
            }
        });
    }
    // Add method to check if data is loaded
    isDataLoaded() {
        return this.blockMaps.length > 0;
    }
    // Clean up polling when destroying
    destroy() {
        if (this.pollTimer) {
            window.clearInterval(this.pollTimer);
            this.pollTimer = null;
        }
        if (this.debounceTimer) {
            window.clearTimeout(this.debounceTimer);
            this.debounceTimer = null;
        }
        this.hideDropdown();
        this.blockMaps = [];
        this.lastKnownLuids.clear();
    }
    // Add helper method to truncate text
    truncateSlug(slug) {
        if (slug.length <= BlockAutocomplete.MAX_SLUG_DISPLAY_LENGTH) {
            return slug;
        }
        return slug.substring(0, BlockAutocomplete.MAX_SLUG_DISPLAY_LENGTH - 3) + "...";
    }
    // Add debouncing for search to improve performance
    debouncedUpdateState(searchTerm, editor) {
        if (this.debounceTimer) {
            window.clearTimeout(this.debounceTimer);
        }
        this.debounceTimer = window.setTimeout(() => {
            this.updateState(searchTerm, editor);
        }, this.DEBOUNCE_DELAY);
    }
    // Add cleanup for event listeners
    cleanup() {
        document.removeEventListener("input", this.handleInput);
        document.removeEventListener("keydown", this.handleKeydown);
        document.removeEventListener("click", this.handleClickOutside);
    }
    handleError(error, context) {
        console.error(`${context}:`, error);
        // Could add error reporting service here
    }
    // Add new helper method for inserting text
    insertText(editor, text) {
        const selection = window.getSelection();
        if (!(selection === null || selection === void 0 ? void 0 : selection.rangeCount))
            return;
        const range = selection.getRangeAt(0);
        const currentText = range.startContainer.textContent || "";
        const linkStart = currentText.lastIndexOf("/link", range.startOffset);
        if (linkStart === -1) {
            this.hideDropdown();
            return;
        }
        const newRange = document.createRange();
        newRange.setStart(range.startContainer, linkStart);
        newRange.setEnd(range.startContainer, range.startOffset);
        const textNode = document.createTextNode(text + " ");
        newRange.deleteContents();
        newRange.insertNode(textNode);
        // Position cursor after inserted text
        const endRange = document.createRange();
        endRange.setStart(textNode, text.length + 1);
        endRange.setEnd(textNode, text.length + 1);
        selection.removeAllRanges();
        selection.addRange(endRange);
        editor.focus();
        this.hideDropdown();
    }
}
// Centralize styles
BlockAutocomplete.STYLES = {
    DROPDOWN: `
      position: absolute;
      background: white;
      border: 1px solid #e2e8f0;
      border-radius: 6px;
      max-height: 200px;
      overflow-y: auto;
      z-index: 1000;
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
      font-size: 14px;
      width: 350px;
    `,
    ITEM: `
      padding: 8px 12px;
      cursor: pointer;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #f1f5f9;
    `,
    SELECTED_ITEM: `
      background: #f8fafc;
      border-left: 2px solid #3b82f6;
    `,
    MATCH_COUNT: `
      padding: 4px 12px;
      background: #f8fafc;
      color: #64748b;
      font-size: 12px;
      border-bottom: 1px solid #e2e8f0;
    `,
    HIGHLIGHT: `
      color: #2563eb;
      font-weight: 500;
    `,
};
BlockAutocomplete.POLL_INTERVAL = 5 * 60 * 1000; // 5 minutes in ms
// Add constant for max display length
BlockAutocomplete.MAX_SLUG_DISPLAY_LENGTH = 40;
// Add constants for commonly used values
BlockAutocomplete.CONSTANTS = {
    LINK_PREFIX: "/link",
    ICON_TEXT: "🔗",
    MIN_SEARCH_LENGTH: 1,
};
BlockAutocomplete.ERROR_MESSAGES = {
    FETCH_ERROR: "Failed to fetch block maps",
    INSERT_ERROR: "Failed to insert block",
    INVALID_EDITOR: "Invalid editor element",
};
// Initialize and export the singleton instance
export const blockAutocomplete = BlockAutocomplete.getInstance();
// For backwards compatibility and global access
window.BlockAutocomplete = BlockAutocomplete;
