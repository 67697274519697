// retrieve VAPID PUBLIC KEY FROM BRAINFUL/SETTINGS/BASE.PY
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
const VAPID_PUBLIC_KEY_URL = "/get-vapid-public-key/";
// Store the VAPID public key as a string
let VAPID_PUBLIC_KEY = "";
// Immediately invoked async function to fetch the VAPID key
(() => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const response = yield fetch(VAPID_PUBLIC_KEY_URL);
        if (!response.ok) {
            throw new Error("Failed to fetch VAPID public key");
        }
        const data = yield response.json();
        VAPID_PUBLIC_KEY = data.vapid_public_key;
    }
    catch (error) {
        console.error("Error fetching VAPID public key:", error);
    }
}))();
// Convert base64 string to Uint8Array
function urlBase64ToUint8Array(base64String) {
    const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}
export function registerPushNotifications() {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            // Check if service workers are supported
            if (!("serviceWorker" in navigator) || !("PushManager" in window)) {
                console.error("Push notifications not supported in this browser");
                return false;
            }
            // Register the service worker
            const registration = yield navigator.serviceWorker.register("/service-worker.js");
            console.log("Service Worker registered successfully");
            // Request notification permission
            const permission = (yield Notification.requestPermission());
            if (permission !== "granted") {
                console.log("Notification permission denied");
                return false;
            }
            // Convert the key
            const applicationServerKey = urlBase64ToUint8Array(VAPID_PUBLIC_KEY);
            // Check for existing subscription
            let subscription = yield registration.pushManager.getSubscription();
            if (!subscription) {
                // Create a new subscription
                subscription = yield registration.pushManager.subscribe({
                    userVisibleOnly: true,
                    applicationServerKey: applicationServerKey,
                });
                console.log("Created new push subscription");
            }
            else {
                console.log("Using existing push subscription");
            }
            // Send the subscription to your server
            yield saveSubscriptionToServer(subscription);
            return true;
        }
        catch (error) {
            console.error("Error registering push notifications:", error);
            return false;
        }
    });
}
export function registerServiceWorkerOnly() {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            // Check if service workers are supported
            if (!("serviceWorker" in navigator) || !("PushManager" in window)) {
                console.error("Push notifications not supported in this browser");
                return false;
            }
            // Register the service worker
            const registration = yield navigator.serviceWorker.register("/service-worker.js");
            if (!registration) {
                console.error("Service Worker registration failed");
                return false;
            }
            console.log("Service Worker registered successfully");
            return true;
        }
        catch (error) {
            console.error("Error registering service worker:", error);
            return false;
        }
    });
}
function saveSubscriptionToServer(subscription) {
    return __awaiter(this, void 0, void 0, function* () {
        const response = yield fetch("/subscribe/", {
            // Changed from "/api/subscribe/"
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                subscription: subscription,
            }),
        });
        if (!response.ok) {
            throw new Error("Failed to save subscription");
        }
        return yield response.json();
    });
}
