/**
 * @file touch_scroll.ts
 * @copyright brainpolo 2024
 * @description Enhances touchscreen scrolling within block editors and content areas
 */
/**
 * Initializes touch scrolling for block editors and content areas
 * Fixes issues with scrolling inside block boxes on touchscreen devices
 */
export function initTouchScrolling() {
    const scrollableElements = document.querySelectorAll('[data-touch-scroll="true"], .bmd-editor, .overflow-x-wrap.overflow-y-scroll, #bmd-block-editor-container-*, #block-editor-*');
    scrollableElements.forEach(element => {
        if (element.getAttribute('data-touch-initialized') === 'true') {
            return;
        }
        element.setAttribute('data-touch-initialized', 'true');
        const currentStyle = element.getAttribute('style') || '';
        element.style.touchAction = 'manipulation';
        if (!currentStyle.includes('-webkit-overflow-scrolling')) {
            element.setAttribute('style', `${element.getAttribute('style') || ''}; -webkit-overflow-scrolling: touch;`);
        }
        element.style.overscrollBehavior = 'contain';
        if (element.classList.contains('bmd-editor')) {
            element.style.minHeight = '140px';
            if (element.parentElement) {
                const parent = element.parentElement;
                parent.style.touchAction = 'manipulation';
                parent.setAttribute('style', `${parent.getAttribute('style') || ''}; -webkit-overflow-scrolling: touch;`);
                parent.style.overscrollBehavior = 'contain';
            }
        }
        const oldTouchStart = element.onpointerdown;
        const oldTouchMove = element.onpointermove;
        element.onpointerdown = null;
        element.onpointermove = null;
        if (element.scrollHeight > element.clientHeight) {
            element.addEventListener('pointerdown', function (e) {
                if (e.pointerType === 'touch') {
                    console.log('Touch start detected on scrollable element');
                }
            }, { passive: true });
            element.addEventListener('pointermove', function (e) {
                if (e.pointerType === 'touch') {
                    console.log('Touch move detected on scrollable element');
                }
            }, { passive: true });
        }
        console.log('Enhanced touch scrolling initialized for element:', element);
    });
}
/**
 * Fixes content visibility issues on smaller screens
 * Ensures content is not cut off from the sides
 */
export function ensureContentVisibility() {
    const mainContent = document.getElementById('main-content');
    if (mainContent) {
        mainContent.style.minWidth = '320px';
        mainContent.style.maxWidth = '100vw';
        mainContent.style.overflowX = 'hidden';
    }
    const blockPanels = document.querySelectorAll('.block-panel');
    blockPanels.forEach(panel => {
        panel.style.minWidth = '300px';
        panel.style.minHeight = '200px';
        panel.style.maxWidth = '95vw';
        panel.style.maxHeight = '90vh';
    });
}
/**
 * Initialize touch scrolling when DOM is loaded and when HTMX content is swapped
 */
export function setupTouchScrollHandlers() {
    document.addEventListener('DOMContentLoaded', () => {
        initTouchScrolling();
        ensureContentVisibility();
    });
    document.body.addEventListener('htmx:afterSwap', () => {
        initTouchScrolling();
        ensureContentVisibility();
    });
}
export default function initTouchScrollSupport() {
    setupTouchScrollHandlers();
}
