/**
 * Browser Fingerprinting Module
 * ----------------------------
 * This module provides comprehensive browser fingerprinting capabilities by collecting
 * various browser attributes and characteristics to create a unique device identifier.
 *
 * Key Features:
 * - Hardware information (CPU cores, memory, screen resolution)
 * - Browser capabilities (WebGL, Canvas, WebRTC support)
 * - System information (timezone, language, fonts)
 * - Device characteristics (touch support, battery level)
 * - Network information
 *
 * Security Considerations:
 * - Some browsers may block or restrict access to certain APIs
 * - Users with privacy-focused extensions may receive different results
 * - Results may vary across different browser versions and platforms
 *
 * @returns {Promise<BrowserFingerprint>} A promise that resolves to a comprehensive
 *          browser fingerprint object containing various device and browser characteristics
 *
 * @throws {Error} If critical fingerprinting operations fail
 *
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// Export the function directly instead of attaching to window
export function getBrowserFingerprint() {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const [canvasFingerprint, webGL, audioFingerprint] = yield Promise.all([
                getCanvasFingerprint(),
                getWebGLFingerprint(),
                getAudioFingerprint()
            ]);
            const fingerprint = {
                userAgent: navigator.userAgent,
                language: navigator.language,
                hardwareConcurrency: typeof navigator.hardwareConcurrency !== 'undefined'
                    ? navigator.hardwareConcurrency
                    : 'NA',
                deviceMemory: 'deviceMemory' in navigator
                    ? navigator.deviceMemory
                    : 'NA',
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                maxTouchPoints: typeof navigator.maxTouchPoints !== 'undefined'
                    ? navigator.maxTouchPoints
                    : 'NA',
                touchSupport: 'ontouchstart' in window ? 'Yes' : 'No',
                clipboardSupport: 'clipboard' in navigator ? 'Yes' : 'No',
                fontList: yield getFontList(),
                fontSize: window.getComputedStyle(document.documentElement).fontSize,
                locale: navigator.language || 'unknown',
                features: {
                    serviceWorker: 'serviceWorker' in navigator,
                    webPush: 'PushManager' in window,
                    localStorage: 'localStorage' in window,
                    sessionStorage: 'sessionStorage' in window,
                    indexedDB: 'indexedDB' in window,
                    cookiesEnabled: navigator.cookieEnabled,
                    webRTC: 'RTCPeerConnection' in window,
                    webAssembly: typeof WebAssembly === 'object',
                    webGL: !!window.WebGLRenderingContext,
                    canvas: !!window.CanvasRenderingContext2D,
                    audio: !!window.AudioContext || !!window.webkitAudioContext,
                    webWorker: !!window.Worker,
                    pdfViewer: 'PDFViewer' in window,
                    bluetooth: 'bluetooth' in navigator,
                },
                screenResolution: `${window.screen.width}x${window.screen.height}`,
                colorDepth: window.screen.colorDepth,
                canvasFingerprint,
                webGL,
                platform: navigator.platform,
                oscpu: navigator.oscpu || 'NA',
                cpuClass: navigator.cpuClass,
                devicePixelRatio: window.devicePixelRatio,
                audioFingerprint,
                screenGamut: screen.colorGamut || 'NA',
                screenContrast: screen.contrast || 'NA',
                doNotTrack: navigator.doNotTrack,
                gpuVendor: webGL.vendor,
                touchForce: 'ontouchforcechange' in window,
                maxPoints: navigator.maxTouchPoints || 0,
                mathFingerprint: getMathFingerprint(),
            };
            return fingerprint;
        }
        catch (error) {
            console.error('Error generating browser fingerprint:', error);
            throw error;
        }
    });
}
function getFontList() {
    return __awaiter(this, void 0, void 0, function* () {
        if (!document.fonts || !document.fonts.ready) {
            return [];
        }
        try {
            yield document.fonts.ready;
            return Array.from(document.fonts, font => font.family);
        }
        catch (_a) {
            return [];
        }
    });
}
function getCanvasFingerprint() {
    try {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        if (!context)
            return '';
        canvas.width = 200;
        canvas.height = 50;
        // Add more complex drawing operations for unique fingerprint
        context.textBaseline = 'top';
        context.font = '14px Arial';
        context.fillStyle = '#f60';
        context.fillRect(125, 1, 62, 20);
        context.fillStyle = '#069';
        context.fillText('Fingerprint Test', 2, 15);
        context.fillStyle = 'rgba(102, 204, 0, 0.7)';
        context.fillText('Canvas Test', 4, 45);
        return canvas.toDataURL();
    }
    catch (_a) {
        return '';
    }
}
function getWebGLFingerprint() {
    try {
        const canvas = document.createElement('canvas');
        const gl = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');
        if (!gl) {
            throw new Error('WebGL not supported');
        }
        const debugInfo = gl.getExtension('WEBGL_debug_renderer_info');
        if (!debugInfo) {
            throw new Error('WebGL debug info not available');
        }
        return {
            vendor: gl.getParameter(debugInfo.UNMASKED_VENDOR_WEBGL) || 'NA',
            renderer: gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL) || 'NA'
        };
    }
    catch (_a) {
        return { vendor: 'NA', renderer: 'NA' };
    }
}
function getAudioFingerprint() {
    return new Promise((resolve) => {
        try {
            const audioContext = new (window.AudioContext || window.webkitAudioContext)();
            const oscillator = audioContext.createOscillator();
            const analyser = audioContext.createAnalyser();
            const gainNode = audioContext.createGain();
            gainNode.gain.value = 0; // Mute the sound
            oscillator.type = 'triangle';
            oscillator.connect(analyser);
            analyser.connect(gainNode);
            gainNode.connect(audioContext.destination);
            oscillator.start(0);
            // Get data directly from analyzer
            const dataArray = new Float32Array(analyser.frequencyBinCount);
            analyser.getFloatTimeDomainData(dataArray);
            // Process first 50 samples
            const hashData = Array.from(dataArray.slice(0, 50)).join(',');
            oscillator.stop();
            audioContext.close();
            resolve(hashData);
            // Fallback if audio processing fails
            setTimeout(() => resolve(''), 1000);
        }
        catch (_a) {
            resolve('');
        }
    });
}
function getMathFingerprint() {
    const calculations = [
        Math.PI,
        Math.E,
        Math.sqrt(2),
        Math.cos(Math.PI),
        Math.sin(Math.PI),
        Math.tan(Math.PI),
        Math.acos(-1),
        Math.fround(3.14159265359),
        Math.imul(0xffffffff, 5),
        Math.pow(2, 31) - 1,
        Math.asinh(1),
        Math.expm1(1),
        Math.hypot(3, 4),
        Math.log1p(1),
        Math.cbrt(27),
        Math.sinh(1),
        Math.tanh(1),
        Math.sign(-5),
        Math.clz32(1),
        Math.log10(100)
    ];
    // Perform complex operations
    const complexOps = [
        () => {
            let sum = 0;
            for (let i = 0; i < 1000; i++) {
                sum += Math.sin(i) * Math.cos(i);
            }
            return sum;
        },
        () => {
            return Math.pow(Math.PI, Math.E) * Math.sqrt(2);
        },
        () => {
            return Math.atan2(Math.PI, Math.E) * Math.LN10;
        }
    ];
    const results = [
        ...calculations,
        ...complexOps.map(op => op())
    ];
    return results.join('|');
}
// Add this at the end of the file
window.getBrowserFingerprint = getBrowserFingerprint;
