// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toast {
    transition: visibility 0.5s, opacity 0.5s, transform 0.5s;
    visibility: hidden;
    opacity: 0;
    transform: translateY(-100%) translateX(-50%);
}

.toast.show-from-top {
    visibility: visible;
    opacity: 0.9;
    animation: slideDown 0.67s ease-in-out forwards;
}

.toast.hiding {
    animation: slideUp 1s ease-in-out forwards;
}`, "",{"version":3,"sources":["webpack://./assets/styles/modules/notification.css"],"names":[],"mappings":"AAAA;IACI,yDAAyD;IACzD,kBAAkB;IAClB,UAAU;IACV,6CAA6C;AACjD;;AAEA;IACI,mBAAmB;IACnB,YAAY;IACZ,+CAA+C;AACnD;;AAEA;IACI,0CAA0C;AAC9C","sourcesContent":[".toast {\n    transition: visibility 0.5s, opacity 0.5s, transform 0.5s;\n    visibility: hidden;\n    opacity: 0;\n    transform: translateY(-100%) translateX(-50%);\n}\n\n.toast.show-from-top {\n    visibility: visible;\n    opacity: 0.9;\n    animation: slideDown 0.67s ease-in-out forwards;\n}\n\n.toast.hiding {\n    animation: slideUp 1s ease-in-out forwards;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
