var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { sendUserMessage } from "../modules/notification";
// DOM Elements
const API_URL = `${window.location.origin}/editor/predict/`;
const PREDICTION_SUCCESS_SOUND_ID = 'prediction-success-sound';
// Core types and state
let isPredictionRunning = false;
const CONTEXT_BEFORE_CURSOR = 600;
const CONTEXT_AFTER_CURSOR = 200;
let lastPredictionContext = null;
// Enums
var PredictionMode;
(function (PredictionMode) {
    PredictionMode["INSERT"] = "insert";
    PredictionMode["REMOVE"] = "remove";
})(PredictionMode || (PredictionMode = {}));
var PredictionType;
(function (PredictionType) {
    PredictionType["CONTEXT_FREE"] = "context-free";
    PredictionType["CONTEXT_SENSITIVE"] = "context-sensitive";
})(PredictionType || (PredictionType = {}));
// Custom Error Class
class PredictionError extends Error {
    constructor(message, context, originalError) {
        super(message);
        this.context = context;
        this.originalError = originalError;
        this.name = 'PredictionError';
    }
}
// Utilities
function logPredictionError(error, operation) {
    if (error instanceof PredictionError) {
        console.error(`Prediction Error during ${operation}:`, {
            message: error.message,
            context: error.context,
            originalError: error.originalError
        });
    }
    else if (error instanceof Error) {
        console.error(`Unexpected error during ${operation}:`, error);
    }
    else {
        console.error(`Unknown error during ${operation}:`, error);
    }
}
const DEBUG = process.env.NODE_ENV !== 'production';
const debugLog = (message, data) => {
    if (DEBUG)
        console.log(`[Prediction Debug] ${message}`, data || '');
};
function validatePrediction(prediction) {
    var _a;
    return !!(prediction &&
        prediction.mode &&
        prediction.type &&
        prediction.suggestion &&
        (prediction.mode !== PredictionMode.REMOVE || ((_a = prediction.fragment) === null || _a === void 0 ? void 0 : _a.trim())));
}
// DOM Utilities
class DOMUtils {
    static getContextFromEditor(editor, selection) {
        if (!(editor === null || editor === void 0 ? void 0 : editor.isContentEditable) || !(selection === null || selection === void 0 ? void 0 : selection.rangeCount)) {
            return { beforeContext: '', afterContext: '', cursorOffset: 0 };
        }
        try {
            const range = selection.getRangeAt(0);
            const textContent = editor.textContent || '';
            const cursorOffset = this.getCursorOffset(editor, range);
            const beforeStart = Math.max(0, cursorOffset - CONTEXT_BEFORE_CURSOR);
            const afterEnd = Math.min(textContent.length, cursorOffset + CONTEXT_AFTER_CURSOR);
            return {
                beforeContext: textContent.substring(beforeStart, cursorOffset),
                afterContext: textContent.substring(cursorOffset, afterEnd),
                cursorOffset
            };
        }
        catch (error) {
            console.error('Error getting context:', error);
            return { beforeContext: '', afterContext: '', cursorOffset: 0 };
        }
    }
    static getCursorOffset(editor, range) {
        let offset = 0;
        const walker = document.createTreeWalker(editor, NodeFilter.SHOW_TEXT);
        let node;
        while ((node = walker.nextNode())) {
            if (node === range.startContainer) {
                return offset + range.startOffset;
            }
            offset += (node.textContent || '').length;
        }
        return offset;
    }
}
// UI Components
class PredictionUI {
    static createSuggestionSpan(suggestion, mode, type, offset, rationale, isLastSuggestion, fragment) {
        const container = document.createElement('span');
        container.className = 'token-prediction-container';
        container.style.position = 'relative';
        const predictionSpan = document.createElement('span');
        predictionSpan.className = `token-prediction token-prediction-${mode}`;
        predictionSpan.textContent = suggestion;
        predictionSpan.dataset.mode = mode;
        predictionSpan.dataset.type = type;
        if (mode === PredictionMode.REMOVE && fragment) {
            predictionSpan.dataset.fragment = fragment;
        }
        predictionSpan.style.cssText = `
            opacity: 0.8; display: inline; visibility: visible; white-space: pre-wrap; word-wrap: break-word;
            position: relative; pointer-events: auto; z-index: 1000; padding: 0 2px; margin: 0 1px;
            font-style: italic; text-decoration: ${mode === PredictionMode.REMOVE ? 'line-through' : 'none'};
            color: ${mode === PredictionMode.INSERT ? '#6366f1' : '#ef4444'};
            background-color: ${mode === PredictionMode.INSERT ? 'rgba(99, 102, 241, 0.1)' : 'rgba(239, 68, 68, 0.1)'};
            border-bottom: 1px dashed ${mode === PredictionMode.INSERT ? '#6366f1' : '#ef4444'};
        `;
        const tooltip = document.createElement('span');
        tooltip.className = 'token-prediction-tooltip';
        tooltip.style.cssText = `
            position: absolute; bottom: 100%; left: 50%; transform: translateX(-50%);
            background: #1f2937; color: white; padding: 8px 12px; border-radius: 6px;
            font-size: 12px; line-height: 1.4; white-space: normal; max-width: 300px; width: max-content;
            opacity: 0; transition: opacity 0.2s; pointer-events: none; z-index: 1001;
            box-shadow: 0 4px 6px rgba(0,0,0,0.1), 0 2px 4px rgba(0,0,0,0.06);
        `;
        const typeTag = document.createElement('span');
        typeTag.style.cssText = `
            display: inline-block; padding: 2px 6px; border-radius: 4px;
            font-size: 10px; font-weight: bold; text-transform: uppercase; margin-bottom: 4px;
            background: ${type === PredictionType.CONTEXT_FREE ? '#4b5563' : '#6366f1'}; color: white;
        `;
        typeTag.textContent = type.replace('-', ' ');
        const rationaleText = document.createElement('div');
        rationaleText.style.marginTop = '4px';
        rationaleText.textContent = rationale;
        tooltip.append(typeTag, rationaleText);
        let hoverTimeout;
        container.addEventListener('mouseenter', () => {
            clearTimeout(hoverTimeout);
            tooltip.style.opacity = '1';
        });
        container.addEventListener('mouseleave', () => {
            hoverTimeout = window.setTimeout(() => {
                tooltip.style.opacity = '0';
            }, 100);
        });
        container.append(predictionSpan, tooltip);
        return container;
    }
    static createTabIndicator() {
        const tabText = document.createElement('span');
        tabText.className = 'token-prediction rainbow-text';
        tabText.textContent = '[tab]';
        tabText.style.cssText = `
            font-size: 0.9em; font-family: monospace; margin-left: 4px;
            opacity: 0; transform: translateY(20px);
            transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
            display: inline; visibility: visible; position: relative;
            pointer-events: auto; z-index: 1000;
        `;
        return tabText;
    }
    static animateRemoval(element) {
        return new Promise(resolve => {
            element.style.transition = 'opacity 0.2s ease-out, transform 0.2s ease-out';
            element.style.opacity = '0';
            element.style.transform = 'translateY(5px)';
            setTimeout(() => {
                element.remove();
                resolve();
            }, 200);
        });
    }
}
// API Interaction
function fetchPredictions(blockLuid, context) {
    return __awaiter(this, void 0, void 0, function* () {
        if (!blockLuid || !context)
            throw new PredictionError('Invalid input', { blockLuid, context });
        try {
            debugLog('Fetching predictions', { blockLuid, context });
            const formData = new FormData();
            formData.append('block_luid', blockLuid);
            formData.append('before_context', context.beforeContext);
            formData.append('after_context', context.afterContext);
            const response = yield fetch(API_URL, { method: 'POST', body: formData });
            if (!response.ok) {
                throw new PredictionError('API response not ok', { status: response.status, statusText: response.statusText });
            }
            const data = yield response.json();
            debugLog('Raw API response', data);
            return data.mode.map((mode, index) => ({
                mode: mode,
                type: data.type[index],
                fragment: data.fragment_before_suggestion[index] || '',
                suggestion: data.suggestion[index],
                rationale: data.rationale[index]
            })).filter(validatePrediction);
        }
        catch (error) {
            logPredictionError(error, 'fetch predictions');
            return [];
        }
    });
}
function getCleanEditorText(editor) {
    const textNodes = getAllTextNodes(editor);
    let cleanText = '';
    const offsets = new Map();
    let cleanOffset = 0;
    textNodes.forEach(node => {
        const content = node.textContent || '';
        cleanText += content;
        for (let i = 0; i < content.length; i++) {
            offsets.set(cleanOffset + i, cleanOffset + i);
        }
        cleanOffset += content.length;
    });
    return { text: cleanText, offsets };
}
function findExactPosition(fragment, mode, editor) {
    const { text: cleanText, offsets } = getCleanEditorText(editor);
    const { startOffset, endOffset } = (lastPredictionContext === null || lastPredictionContext === void 0 ? void 0 : lastPredictionContext.editorRange) || {};
    if (mode === PredictionMode.REMOVE && fragment.trim()) {
        const position = cleanText.indexOf(fragment);
        if (position !== -1) {
            const realPosition = offsets.get(position);
            if (realPosition !== undefined && realPosition >= (startOffset || 0) && realPosition <= (endOffset || 0)) {
                return realPosition;
            }
        }
    }
    return (startOffset || 0) + ((lastPredictionContext === null || lastPredictionContext === void 0 ? void 0 : lastPredictionContext.context.cursorOffset) || 0);
}
function getAllTextNodes(element) {
    const nodes = [];
    const walker = document.createTreeWalker(element, NodeFilter.SHOW_TEXT);
    let node;
    while ((node = walker.nextNode()))
        nodes.push(node);
    return nodes;
}
function findNodeAndOffset(nodes, targetOffset) {
    var _a, _b;
    let currentOffset = 0;
    for (const node of nodes) {
        const length = ((_a = node.textContent) === null || _a === void 0 ? void 0 : _a.length) || 0;
        if (currentOffset + length > targetOffset) {
            return { node, offset: targetOffset - currentOffset };
        }
        currentOffset += length;
    }
    const lastNode = nodes[nodes.length - 1];
    return { node: lastNode || null, offset: ((_b = lastNode === null || lastNode === void 0 ? void 0 : lastNode.textContent) === null || _b === void 0 ? void 0 : _b.length) || 0 };
}
function getAllAdjacentTextNodes(span) {
    const nodes = [];
    let sibling = span.previousSibling;
    while (sibling) {
        if (sibling.nodeType === Node.TEXT_NODE) {
            nodes.unshift(sibling);
        }
        else if (!(sibling instanceof Element) || !sibling.classList.contains('token-prediction-container')) {
            break;
        }
        sibling = sibling.previousSibling;
    }
    sibling = span.nextSibling;
    while (sibling) {
        if (sibling.nodeType === Node.TEXT_NODE) {
            nodes.push(sibling);
        }
        else if (!(sibling instanceof Element) || !sibling.classList.contains('token-prediction-container')) {
            break;
        }
        sibling = sibling.nextSibling;
    }
    return nodes;
}
function updateTextNodes(nodes, position, length) {
    let remaining = length;
    for (const node of nodes) {
        const text = node.textContent || '';
        if (position < text.length) {
            const deleteCount = Math.min(remaining, text.length - position);
            node.textContent = text.slice(0, position) + text.slice(position + deleteCount);
            remaining -= deleteCount;
            if (remaining <= 0)
                break;
            position = 0;
        }
        else {
            position -= text.length;
        }
    }
}
function findTextPosition(text, fragment, range) {
    // First try exact match
    const exactMatch = tryMatch(text, fragment, range);
    if (exactMatch)
        return exactMatch;
    // Normalize and try again
    const normalize = (s) => s.toLowerCase().replace(/[.,!?'"]/g, '').replace(/\s+/g, ' ').trim();
    const normalizedText = normalize(text);
    const normalizedFragment = normalize(fragment);
    const normalizedMatch = tryMatch(normalizedText, normalizedFragment, range);
    if (normalizedMatch)
        return normalizedMatch;
    // Progressive word reduction
    const words = normalizedFragment.split(' ');
    for (let wordCount = words.length - 1; wordCount > 0; wordCount--) {
        const reducedFragment = words.slice(0, wordCount).join(' ');
        const match = tryMatch(normalizedText, reducedFragment, range);
        if (match) {
            // Verify uniqueness within range
            const allMatches = findAllMatches(normalizedText, reducedFragment);
            const matchesInRange = allMatches.filter(pos => (!range || (pos >= range.startOffset && pos <= range.endOffset)));
            if (matchesInRange.length === 1) {
                return {
                    position: match.position,
                    matchedText: reducedFragment
                };
            }
        }
    }
    return null;
}
function tryMatch(text, fragment, range) {
    const position = text.indexOf(fragment);
    if (position === -1)
        return null;
    if (range) {
        if (position < range.startOffset || position > range.endOffset)
            return null;
    }
    return { position, matchedText: fragment };
}
function findAllMatches(text, fragment) {
    const positions = [];
    let pos = 0;
    while ((pos = text.indexOf(fragment, pos)) !== -1) {
        positions.push(pos);
        pos += 1;
    }
    return positions;
}
function addSpaceIfNeeded(span, suggestion) {
    var _a, _b;
    const trimmed = suggestion.trim().replace(/\s+/g, ' ');
    const prevText = (((_a = span.previousSibling) === null || _a === void 0 ? void 0 : _a.textContent) || '').replace(/\s+$/, '');
    const nextText = (((_b = span.nextSibling) === null || _b === void 0 ? void 0 : _b.textContent) || '').replace(/^\s+/, '');
    const needsLeading = prevText && /\w$/.test(prevText) && /^\w/.test(trimmed);
    const needsTrailing = nextText && /\w$/.test(trimmed) && /^\w/.test(nextText);
    return `${needsLeading ? ' ' : ''}${trimmed}${needsTrailing ? ' ' : ''}`;
}
function cleanupSpan(span) {
    span.style.transition = 'none';
    span.remove();
}
function updateCursorPosition(node, selection) {
    if (node === null || node === void 0 ? void 0 : node.parentNode) {
        const range = document.createRange();
        range.setStartAfter(node);
        range.collapse(true);
        selection.removeAllRanges();
        selection.addRange(range);
    }
}
// Prediction Handlers
function handleRemovePrediction(span, suggestion) {
    return __awaiter(this, void 0, void 0, function* () {
        var _a;
        if (!(lastPredictionContext === null || lastPredictionContext === void 0 ? void 0 : lastPredictionContext.editorRange)) {
            throw new PredictionError('No valid prediction context');
        }
        const fragment = (_a = span.dataset.fragment) === null || _a === void 0 ? void 0 : _a.trim();
        if (!fragment) {
            throw new PredictionError('Missing fragment for removal');
        }
        const nodes = getAllAdjacentTextNodes(span);
        if (!nodes.length) {
            cleanupSpan(span);
            return;
        }
        const editorText = lastPredictionContext.editorRange.text;
        const fragmentToFind = fragment;
        // Find all occurrences of the fragment in the context
        const occurrences = [];
        let pos = -1;
        while ((pos = editorText.indexOf(fragmentToFind, pos + 1)) !== -1) {
            occurrences.push(pos);
        }
        // If exactly one occurrence, we can safely remove it
        if (occurrences.length === 1) {
            const position = occurrences[0];
            updateTextNodes(nodes, position, fragmentToFind.length);
        }
        cleanupSpan(span);
    });
}
function handleInsertPrediction(span, suggestion) {
    if (!(lastPredictionContext === null || lastPredictionContext === void 0 ? void 0 : lastPredictionContext.editorRange))
        return document.createTextNode(suggestion);
    if (!span.parentNode)
        return document.createTextNode(suggestion);
    const parent = span.parentNode;
    const spaceAdjusted = addSpaceIfNeeded(span, suggestion);
    const textNode = document.createTextNode(spaceAdjusted);
    try {
        parent.replaceChild(textNode, span);
    }
    catch (_a) {
        parent.insertBefore(textNode, span.nextSibling);
        cleanupSpan(span);
    }
    return textNode;
}
function createPredictionElements(editor, predictions, range) {
    const operations = processPredictions(editor, predictions);
    const elements = [];
    operations.forEach(op => {
        var _a;
        const nodes = getAllTextNodes(editor);
        const { node, offset } = findNodeAndOffset(nodes, op.originalPosition);
        if (!node || offset > (((_a = node.textContent) === null || _a === void 0 ? void 0 : _a.length) || 0))
            return;
        const span = PredictionUI.createSuggestionSpan(op.suggestion, op.mode, op.type, offset, op.rationale, false, op.fragment);
        const predictionRange = document.createRange();
        predictionRange.setStart(node, offset);
        predictionRange.collapse(true);
        predictionRange.insertNode(span);
        elements.push({
            span,
            offset,
            absoluteOffset: op.originalPosition,
            suggestion: op.suggestion,
            mode: op.mode,
            type: op.type,
            rationale: op.rationale
        });
    });
    return elements;
}
function processPredictions(editor, predictions) {
    return predictions
        .filter(validatePrediction)
        .map(pred => {
        const position = findExactPosition(pred.fragment, pred.mode, editor);
        if (position === null)
            return null;
        const { node, offset } = findNodeAndOffset(getAllTextNodes(editor), position);
        if (!node)
            return null;
        return {
            mode: pred.mode,
            type: pred.type,
            fragment: pred.fragment,
            suggestion: pred.suggestion,
            originalPosition: position,
            length: pred.mode === PredictionMode.REMOVE ? pred.fragment.length : pred.suggestion.length,
            textNode: node,
            offset,
            rationale: pred.rationale
        };
    })
        .filter((op) => op !== null)
        .sort((a, b) => a.mode !== b.mode ? (a.mode === PredictionMode.REMOVE ? -1 : 1) : b.originalPosition - a.originalPosition);
}
// Prediction Acceptance
function acceptPredictions(editor, predictions, selection, event) {
    return __awaiter(this, void 0, void 0, function* () {
        var _a;
        event === null || event === void 0 ? void 0 : event.preventDefault();
        try {
            yield playSuccessSound();
            // Get original cursor position from stored context
            const originalCursorPosition = (_a = lastPredictionContext === null || lastPredictionContext === void 0 ? void 0 : lastPredictionContext.cursorPosition) !== null && _a !== void 0 ? _a : 0;
            // Sort predictions - REMOVE first, then INSERT
            const sortedPredictions = sortPredictionsByPosition(predictions);
            // Track statistics
            let wordsAdded = 0;
            let wordsRemoved = 0;
            if (DEBUG) {
                console.log('Processing predictions for word counting:', sortedPredictions);
            }
            // Count words before applying predictions
            for (const pred of sortedPredictions) {
                if (pred.mode === PredictionMode.INSERT) {
                    // Count actual words (excluding whitespace and empty strings)
                    const words = pred.suggestion
                        .trim()
                        .split(/\s+/)
                        .filter(word => word.length > 0 && /\w+/.test(word));
                    wordsAdded += words.length;
                    if (DEBUG) {
                        console.log('INSERT -', {
                            suggestion: pred.suggestion,
                            words,
                            count: words.length
                        });
                    }
                }
                else if (pred.mode === PredictionMode.REMOVE) {
                    const fragment = pred.span.dataset.fragment;
                    if (fragment) {
                        // Count actual words (excluding whitespace and empty strings)
                        const words = fragment
                            .trim()
                            .split(/\s+/)
                            .filter(word => word.length > 0 && /\w+/.test(word));
                        wordsRemoved += words.length;
                        if (DEBUG) {
                            console.log('REMOVE -', {
                                fragment,
                                words,
                                count: words.length
                            });
                        }
                    }
                }
            }
            if (DEBUG) {
                console.log('Final word counts:', {
                    added: wordsAdded,
                    removed: wordsRemoved,
                    predictions: sortedPredictions.length
                });
            }
            // Apply the predictions in sorted order
            applyPredictions(sortedPredictions);
            // Restore cursor to original position
            const nodes = getAllTextNodes(editor);
            const { node, offset } = findNodeAndOffset(nodes, originalCursorPosition);
            if (node) {
                const range = document.createRange();
                range.setStart(node, offset);
                range.collapse(true);
                selection.removeAllRanges();
                selection.addRange(range);
            }
            // Build message with accurate word counts
            let message = `${predictions.length} suggestion${predictions.length !== 1 ? 's' : ''} accepted`;
            if (wordsRemoved > 0) {
                message += `, removed ${wordsRemoved} word${wordsRemoved !== 1 ? 's' : ''}`;
            }
            if (wordsAdded > 0) {
                message += `, added ${wordsAdded} word${wordsAdded !== 1 ? 's' : ''}`;
            }
            sendUserMessage('success', message);
            yield clearExistingPredictions(editor);
            scheduleNextPrediction(editor);
        }
        catch (error) {
            console.error('Error accepting predictions:', error);
            yield clearExistingPredictions(editor);
        }
    });
}
function sortPredictionsByPosition(predictions) {
    return [...predictions].sort((a, b) => {
        if (a.mode !== b.mode)
            return a.mode === PredictionMode.REMOVE ? -1 : 1;
        return b.absoluteOffset - a.absoluteOffset;
    });
}
function applyPredictions(predictions) {
    let lastNode = null;
    predictions.forEach(({ span, suggestion, mode }) => {
        if (mode === PredictionMode.REMOVE) {
            handleRemovePrediction(span, suggestion).catch(err => logPredictionError(err, 'remove prediction'));
        }
        else {
            lastNode = handleInsertPrediction(span, suggestion);
        }
    });
    return lastNode;
}
function playSuccessSound() {
    const sound = document.getElementById(PREDICTION_SUCCESS_SOUND_ID);
    return (sound === null || sound === void 0 ? void 0 : sound.play().catch(console.error)) || Promise.resolve();
}
// Prediction Cleanup
function clearExistingPredictions(editor) {
    return __awaiter(this, void 0, void 0, function* () {
        const predictions = Array.from(editor.querySelectorAll('.token-prediction, .token-prediction-container'));
        yield Promise.all(predictions.map(el => PredictionUI.animateRemoval(el).catch(() => el.remove())));
    });
}
// Event Handlers Setup
function setupPredictionHandlers(editor, predictions, selection) {
    const handleKeydown = (event) => __awaiter(this, void 0, void 0, function* () {
        if (event.key === 'Tab') {
            event.preventDefault();
            yield acceptPredictions(editor, predictions, selection, event);
        }
        else {
            yield clearExistingPredictions(editor);
        }
    });
    const handleCleanup = () => __awaiter(this, void 0, void 0, function* () {
        yield clearExistingPredictions(editor);
        cleanup();
    });
    const cleanup = () => {
        editor.removeEventListener('keydown', handleKeydown);
        editor.removeEventListener('click', handleCleanup);
        editor.removeEventListener('blur', handleCleanup);
        window.removeEventListener('scroll', handleCleanup);
        window.removeEventListener('resize', handleCleanup);
    };
    editor.addEventListener('keydown', handleKeydown);
    editor.addEventListener('click', handleCleanup);
    editor.addEventListener('blur', handleCleanup);
    window.addEventListener('scroll', handleCleanup);
    window.addEventListener('resize', handleCleanup);
    return cleanup;
}
// Prediction Processing
export function predictNextTokens(editor, blockLuid) {
    return __awaiter(this, void 0, void 0, function* () {
        if (!(editor === null || editor === void 0 ? void 0 : editor.isContentEditable) || !blockLuid || isPredictionRunning)
            return;
        isPredictionRunning = true;
        try {
            const selection = window.getSelection();
            if (!(selection === null || selection === void 0 ? void 0 : selection.rangeCount))
                return;
            yield clearExistingPredictions(editor);
            const context = DOMUtils.getContextFromEditor(editor, selection);
            const editorText = editor.textContent || '';
            const contextText = context.beforeContext + context.afterContext;
            const contextStart = Math.max(0, editorText.indexOf(contextText));
            const contextEnd = contextStart + contextText.length;
            // Store the cursor position along with other context
            lastPredictionContext = {
                context,
                editorRange: {
                    startOffset: contextStart,
                    endOffset: contextEnd,
                    text: contextText
                },
                cursorPosition: context.cursorOffset
            };
            const predictions = yield fetchPredictions(blockLuid, context);
            if (predictions.length) {
                const elements = createPredictionElements(editor, predictions, selection.getRangeAt(0));
                if (elements.length)
                    setupPredictionHandlers(editor, elements, selection);
            }
        }
        catch (error) {
            console.error('Error in predictNextTokens:', error);
        }
        finally {
            isPredictionRunning = false;
        }
    });
}
function scheduleNextPrediction(editor) {
    const blockLuid = editor.getAttribute('data-block-luid');
    if (blockLuid)
        setTimeout(() => predictNextTokens(editor, blockLuid), 50);
}
