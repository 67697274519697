/**
 * ! File: index.ts
 * ! Author: Aditya Dedhia
 * ! Description: Entry point for the Webpack bundle.
 */
"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// Webpack entry point
import hljs from "highlight.js";
import "highlight.js/styles/github-dark.css"; // or any other style you prefer
import interact from "interactjs";
import * as Diff from "diff";
import { Chart } from "chart.js/auto";
import { registerPushNotifications, registerServiceWorkerOnly } from "./push-notifications";
import { typedHTMX } from "./modules/htmx";
import * as _hyperscript from "hyperscript.org";
import { BlockAutocomplete } from "./modules/block_autocomplete";
import initTouchScrollSupport, { initTouchScrolling, ensureContentVisibility } from "./modules/touch_scroll";
_hyperscript.browserInit();
// Import bootstrap icons
import "bootstrap-icons/font/bootstrap-icons.css";
// Leaflet
import * as L from "leaflet";
import "leaflet/dist/leaflet.css";
// Fix Leaflet's default icon paths
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});
import "../styles/modules/notification.css";
import { createMessageToast, sendUserMessage } from "./modules/notification";
import { getBrowserFingerprint } from "./modules/fingerprint";
import { predictNextTokens } from "./modules/prediction";
import { initialiseBlockScanner } from "./modules/feature";
// Non TWCSS Imports
import "../styles/index.css";
import "../styles/block.css";
// Fix the context imports
const audioContext = require.context("../audio", false, /\.(mp3|wav|ogg)$/);
const audioFiles = audioContext.keys().map((key) => audioContext(key));
// Fix the image imports
const imageContext = require.context("../images", true, /\.(png|svg|jpg|jpeg|gif|ico)$/);
const imageFiles = imageContext.keys().map((key) => imageContext(key));
window.htmx = typedHTMX;
window.registerPushNotifications = registerPushNotifications;
window.registerServiceWorkerOnly = registerServiceWorkerOnly;
window.sendUserMessage = sendUserMessage;
window.getBrowserFingerprint = getBrowserFingerprint;
window.predictNextTokens = predictNextTokens;
window.hljs = hljs;
window.interact = interact;
window.Diff = Diff;
window.Chart = Chart;
window.initialiseBlockScanner = initialiseBlockScanner;
window.BlockAutocomplete = BlockAutocomplete;
window.initTouchScrolling = initTouchScrolling;
window.ensureContentVisibility = ensureContentVisibility;
document.addEventListener("DOMContentLoaded", () => __awaiter(void 0, void 0, void 0, function* () {
    // Initialize highlight.js
    hljs.highlightAll();
    initTouchScrollSupport();
    // Display Django server messages
    typedHTMX.on("messages", (event) => {
        event.detail.value.forEach(createMessageToast);
    });
    // Prevent zooming
    document.addEventListener("wheel", (event) => {
        if (event.ctrlKey) {
            event.preventDefault();
        }
    }, { passive: false });
    console.log("Webpack correctly initialised.");
}));
