/**
 * Feature Module
 * ----------------------------
 * This module provides a comprehensive feature detection and assessment system.
 * Objective is to scan each block for features and assess them.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { diffChars } from "diff";
const BASE_FEATURE_ASSESSMENT_URL = document.location.origin + "/my/editor/assess/placeholder_luid/placeholder_id";
const SCANNABLE_FEATURE_BASE_URL = document.location.origin + "/my/editor/update-block-features/placeholder_luid";
const EDITOR_CLASS = "bmd-editor";
const LUID_ATTRIBUTE = "data-luid";
// * Feature HTML Classes/Attributes
const FEATURE_INDICATOR_CLASS = "feature-indicator";
const FEATURE_POPUP_CARD_CLASS = "feature-popup-card";
const FEATURE_POPUP_CONTENT_CLASS = "feature-popup-content";
const FEATURE_REVISION_SIDE_CLASS = "feature-revision-side";
const FEATURE_REVISION_HEADER_CLASS = "feature-revision-header";
const FEATURE_REVISION_CONTENT_CLASS = "feature-revision-content";
const FEATURE_DESCRIPTION_SIDE_CLASS = "feature-description-side";
const FEATURE_DESCRIPTION_CONTENT_CLASS = "feature-description";
const FEATURE_ACCEPT_BUTTON_CLASS = "feature-accept-button";
const REVISION_SUCCESS_SOUND_ID = "revision-success-sound";
const FEATURE_ATTRIBUTE = "data-feature-id";
const FEATURE_STATUS_ATTRIBUTE = "data-feature-status";
const FEATURE_REVISION_ATTRIBUTE = "data-feature-revision";
const FEATURE_DESCRIPTION_ATTRIBUTE = "data-feature-description";
const MIN_FEATURE_CHARS = 30; // 30 characters is ~ 1 sentence
const SCANNABLE_TAGS = ["p", "td", "li"];
const FEATURE_ASSESSMENT_RETRY_INTERVAL_MS = 30000; // 30 seconds after initial attempt
const ACTIVE_FEATURE_ASSESSMENT_RETRY_INTERVAL_MS = 10000; // 10 seconds after user keydown
// Store references to DOM element (features) that have been processed
var processed_block_features = new WeakMap();
var feature_interval_map = new Map(); // map of feature ids to intervals
// Store existing feature hashes to only apply assessment if it has changed
let existing_features_hash_map = new Map();
const feature_status_icons = {
    0: "bi-check-all",
    1: "bi-check-lg",
    2: "bi-exclamation-triangle",
    3: "bi-x-lg",
};
const feature_status_classes = {
    0: "text-green-800",
    1: "text-yellow-600",
    2: "text-orange-500",
    3: "text-red-500",
};
// Add constants for visual styling
const POPUP_ANIMATION_DURATION = 150;
const POPUP_HOVER_DELAY = 1500;
const POPUP_Z_INDEX = 1000;
// Add debounce utility
function debounce(func, wait) {
    let timeout;
    return function executedFunction(...args) {
        const later = () => {
            clearTimeout(timeout);
            func(...args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
}
/**
 * Generate a one-way consistent SHA-256 hash of the given text.
 *
 * @param {string} text - The text to hash
 * @returns {Promise<string>} A promise that resolves to the SHA-256 hash of the text
 */
function generateHashAsync(text) {
    return __awaiter(this, void 0, void 0, function* () {
        const encoder = new TextEncoder();
        const data = encoder.encode(text);
        const hashBuffer = yield crypto.subtle.digest("SHA-256", data);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        return hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");
    });
}
/**
 * Highlight changes between two HTML strings.
 *
 * @param {string} original - The original HTML string
 * @param {string} revision - The revised HTML string
 * @returns {string} The highlighted HTML string
 */
function highlightChanges(original, revision) {
    if (original === revision || revision === "") {
        return original;
    }
    const diff = diffChars(original, revision);
    let highlightedHTML = "";
    diff.forEach((part) => {
        if (part.added) {
            highlightedHTML += `<ins style="background-color: lightgreen; text-decoration: none;">${part.value}</ins>`;
        }
        else if (part.removed) {
            highlightedHTML += `<del style="background-color: lightpink; text-decoration: none;">${part.value}</del>`;
        }
        else {
            highlightedHTML += part.value;
        }
    });
    return highlightedHTML;
}
function generateFeatureID(existing_feature_ids_list) {
    // * 1. Validate arguments
    if (!Array.isArray(existing_feature_ids_list)) {
        throw new Error("Invalid input: existing_feature_ids_list must be an array");
    }
    // * 2. Generate feature ID
    const FEATURE_LIMIT = 7000;
    const ID_LENGTH = 3;
    const MAX_ATTEMPTS = 1000; // Prevent infinite loops
    if (existing_feature_ids_list.length >= FEATURE_LIMIT) {
        throw new Error(`Feature limit (${FEATURE_LIMIT}) exceeded for block. Cannot generate more features.`);
    }
    // * 3. Generate ID
    const characters = "abcdefghijklmnopqrstuvwxyz0123456789";
    const existingSet = new Set(existing_feature_ids_list); // For faster lookups
    let attempts = 0;
    while (attempts < MAX_ATTEMPTS) {
        const result = Array.from({ length: ID_LENGTH }, () => characters.charAt(Math.floor(Math.random() * characters.length))).join("");
        if (!existingSet.has(result)) {
            return result;
        }
        attempts++;
    }
    throw new Error("Failed to generate unique ID after maximum attempts");
}
// Fix FeatureQueue class
class FeatureQueue {
    static push(feature) {
        if (!feature)
            return;
        this.queue.push(feature);
        if (!this.isProcessing) {
            // Only start processing if not already running
            this.process();
        }
    }
    static pop() {
        return this.queue.shift();
    }
    static isEmpty() {
        return this.queue.length === 0;
    }
    static process() {
        return __awaiter(this, void 0, void 0, function* () {
            var _a;
            if (this.isProcessing)
                return; // Prevent concurrent processing
            this.isProcessing = true;
            const MAX_RETRIES = 3;
            const RETRY_DELAY = 1000;
            try {
                while (!this.isEmpty()) {
                    const feature = this.pop();
                    if (!feature)
                        continue;
                    const block_luid = (_a = feature.closest(`.${EDITOR_CLASS}`)) === null || _a === void 0 ? void 0 : _a.getAttribute(LUID_ATTRIBUTE);
                    const feature_id = feature.getAttribute(FEATURE_ATTRIBUTE);
                    if (!block_luid || !feature_id)
                        continue;
                    let retries = 0;
                    while (retries < MAX_RETRIES) {
                        try {
                            yield requestFeatureAssessment(feature, block_luid, feature_id);
                            break;
                        }
                        catch (error) {
                            console.warn(`Attempt ${retries + 1} failed:`, error);
                            retries++;
                            if (retries < MAX_RETRIES) {
                                yield new Promise((resolve) => setTimeout(resolve, RETRY_DELAY));
                            }
                        }
                    }
                    yield new Promise((resolve) => setTimeout(resolve, this.FEATURE_DELAY_MS));
                }
            }
            finally {
                this.isProcessing = false; // Always reset flag when done
            }
        });
    }
}
FeatureQueue.FEATURE_DELAY_MS = 1000; // 1 seconds delay to match server rate limiting
FeatureQueue.queue = [];
FeatureQueue.isProcessing = false; // Add a flag to track processing state
// Fix popup card contentEditable
function applyFeatureAssessment(feature, status, revision, description, timestamp) {
    // * 1. Validate arguments
    if (!feature || !(feature instanceof HTMLElement)) {
        throw new Error("Invalid feature: must be a DOM element");
    }
    if (![0, 1, 2, 3].includes(status)) {
        throw new Error("Feature assessment: Invalid status: must be 0, 1, 2, or 3");
    }
    if (typeof revision !== "string") {
        throw new Error("Feature assessment: Invalid revision: must be a string");
    }
    if (typeof description !== "string") {
        throw new Error("Feature assessment: Invalid description: must be a string");
    }
    if (typeof timestamp !== "string") {
        throw new Error("Feature assessment: Invalid timestamp: must be a string");
    }
    console.debug("Applying feature assessment:", { status, revision, description, timestamp });
    // * 2. Check if indicator span already exists
    let indicator_span = feature.querySelector(`.${FEATURE_INDICATOR_CLASS}`);
    if (indicator_span) {
        indicator_span.remove();
    }
    // * 3. Create a new indicator span
    indicator_span = document.createElement("span");
    indicator_span.classList.add(FEATURE_INDICATOR_CLASS);
    // * 4. Create icon
    const icon = document.createElement("i");
    icon.classList.add("bi", feature_status_icons[status]);
    icon.classList.add(feature_status_classes[status]);
    indicator_span.appendChild(icon);
    // * 5. Append indicator to the feature
    feature.appendChild(indicator_span);
    // * 6. Create popup card with flex layout
    const popup_card = document.createElement("div");
    popup_card.classList.add(FEATURE_POPUP_CARD_CLASS, "shadow-lg", "rounded-lg", "border", "border-gray-200", "bg-white");
    popup_card.style.position = "absolute";
    popup_card.style.display = "none";
    popup_card.style.zIndex = POPUP_Z_INDEX.toString();
    popup_card.style.maxWidth = "500px";
    popup_card.style.minWidth = "300px";
    popup_card.setAttribute("contenteditable", "false");
    // Add smooth animation
    popup_card.style.transition = `opacity ${POPUP_ANIMATION_DURATION}ms ease-in-out`;
    popup_card.style.opacity = "0";
    // * 7. Create container for both sides
    const popup_content = document.createElement("div");
    popup_content.classList.add(FEATURE_POPUP_CONTENT_CLASS);
    // * 8. Create revision preview side (left)
    const revision_side = document.createElement("div");
    revision_side.classList.add(FEATURE_REVISION_SIDE_CLASS);
    // Create revision header
    const revision_header = document.createElement("h4");
    revision_header.classList.add(FEATURE_REVISION_HEADER_CLASS);
    revision_header.textContent = "Proposed Revision";
    revision_side.appendChild(revision_header);
    // Create revision content
    const revision_content = document.createElement("div");
    revision_content.classList.add(FEATURE_REVISION_CONTENT_CLASS);
    revision_content.setAttribute("contenteditable", "false");
    const highlighted_html = highlightChanges(feature.innerHTML, revision);
    if (highlighted_html === feature.innerHTML) {
        revision_content.innerHTML = `<div class="flex items-center justify-center p-4 text-green-600">
          <i class="bi bi-check-circle-fill text-2xl mr-2"></i>
          <span class="font-semibold">No advisable changes, Great job!</span>
        </div>`;
    }
    else {
        revision_content.innerHTML = highlighted_html;
    }
    // Append revision side to popup content
    revision_side.appendChild(revision_content);
    // * 9. Create description side (right)
    const description_side = document.createElement("div");
    description_side.classList.add(FEATURE_DESCRIPTION_SIDE_CLASS);
    // Create timestamp header
    const timestamp_div = document.createElement("div");
    timestamp_div.classList.add("feature-timestamp");
    timestamp_div.textContent =
        "Generated: " +
            new Date(timestamp).toLocaleDateString("en-GB", {
                day: "numeric",
                month: "long",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
            });
    description_side.appendChild(timestamp_div);
    // Create description content
    const description_content = document.createElement("div");
    description_content.classList.add(FEATURE_DESCRIPTION_CONTENT_CLASS);
    description_content.textContent = description;
    description_side.appendChild(description_content);
    // Add status button
    if (status !== 0) {
        const accept_button = document.createElement("button");
        accept_button.classList.add(FEATURE_ACCEPT_BUTTON_CLASS, "bg-green-600", "hover:bg-green-700", "text-white", "font-semibold", "py-2", "px-4", "rounded", "transition-colors", "duration-200");
        accept_button.innerHTML = '<i class="bi bi-check-lg mr-2"></i>Accept revision';
        description_side.appendChild(accept_button);
        // Improved button click handling
        accept_button.addEventListener("click", (event) => {
            var _a;
            event.preventDefault();
            event.stopPropagation();
            try {
                feature.innerHTML = revision;
                (_a = feature.parentElement) === null || _a === void 0 ? void 0 : _a.dispatchEvent(new Event("input"));
                // Animate popup close
                popup_card.style.opacity = "0";
                setTimeout(() => {
                    popup_card.style.display = "none";
                }, POPUP_ANIMATION_DURATION);
                // Play sound with error handling
                const sound = document.getElementById(REVISION_SUCCESS_SOUND_ID);
                if (sound) {
                    sound.play().catch((err) => console.warn("Sound playback failed:", err));
                }
            }
            catch (error) {
                console.error("Error accepting revision:", error);
            }
        });
    }
    //* 10. Append sides to content container
    popup_content.appendChild(revision_side);
    popup_content.appendChild(description_side);
    //* 11. Append content container to popup card
    popup_card.appendChild(popup_content);
    //* 12. Append popup to body to prevent embedding
    document.body.appendChild(popup_card);
    // Improved popup positioning and behavior
    const debouncedHidePopup = debounce(() => {
        if (!popup_card.matches(":hover")) {
            popup_card.style.opacity = "0";
            setTimeout(() => {
                popup_card.style.display = "none";
            }, POPUP_ANIMATION_DURATION);
        }
    }, POPUP_HOVER_DELAY);
    indicator_span.addEventListener("mouseover", () => {
        const rect = indicator_span.getBoundingClientRect();
        const viewportHeight = window.innerHeight;
        // Position popup based on viewport space
        if (rect.top > viewportHeight / 2) {
            popup_card.style.bottom = `${viewportHeight - rect.top}px`;
            popup_card.style.top = "auto";
        }
        else {
            popup_card.style.top = `${rect.top}px`;
            popup_card.style.bottom = "auto";
        }
        popup_card.style.left = `${rect.right + 5}px`;
        popup_card.style.display = "block";
        // Trigger animation
        requestAnimationFrame(() => {
            popup_card.style.opacity = "1";
        });
    });
    indicator_span.addEventListener("mouseout", debouncedHidePopup);
    popup_card.addEventListener("mouseleave", debouncedHidePopup);
}
/**
 * Remove feature assessment from DOM element and hash map.
 *
 * @param {HTMLElement} feature - The feature to remove assessment from
 */
function removeFeatureAssessment(feature) {
    // * 1. Validate argument
    if (!(feature instanceof HTMLElement)) {
        throw new Error("Invalid feature: must be a DOM element");
    }
    // * 2. Get feature ID
    const feature_id = feature.getAttribute(FEATURE_ATTRIBUTE);
    if (!feature_id) {
        throw new Error("Feature ID not found");
    }
    // * 3. Clear the assessment interval if it exists
    const interval_id = feature_interval_map.get(feature_id);
    if (interval_id) {
        clearInterval(interval_id);
        feature_interval_map.delete(feature_id);
        console.debug("Cleared interval for feature:", feature_id);
    }
    // * 4. Delete feature from hash map
    existing_features_hash_map.delete(feature_id);
    console.debug("Deleted feature from hash map:", feature_id);
    // * 5. Remove feature assessment attributes
    feature.removeAttribute(FEATURE_ATTRIBUTE);
    feature.removeAttribute(FEATURE_STATUS_ATTRIBUTE);
    feature.removeAttribute(FEATURE_REVISION_ATTRIBUTE);
    feature.removeAttribute(FEATURE_DESCRIPTION_ATTRIBUTE);
}
/**
 * Request feature assessment from server.
 *
 * @param {HTMLElement} feature - The feature to request assessment for
 * @param {string} block_luid - The block LUID
 * @param {string} feature_id - The feature ID
 */
function requestFeatureAssessment(feature, block_luid, feature_id) {
    return __awaiter(this, void 0, void 0, function* () {
        var _a;
        console.log("REQUESTING FEATURE ASSESSMENT FOR:", feature_id, block_luid);
        // * 1. If block editor is not active element in DOM, skip
        if (!((_a = document.activeElement) === null || _a === void 0 ? void 0 : _a.classList.contains(EDITOR_CLASS))) {
            console.log("Block editor is not active element, skipping feature assessment");
            // try again later
            setTimeout(() => {
                FeatureQueue.push(feature);
            }, FEATURE_ASSESSMENT_RETRY_INTERVAL_MS);
            return;
        }
        // * 2. If currently user keydown, recall after 5 seconds
        if (document.activeElement === feature) {
            setTimeout(() => {
                FeatureQueue.push(feature);
            }, ACTIVE_FEATURE_ASSESSMENT_RETRY_INTERVAL_MS);
            return;
        }
        // * 3. If feature indicator already exists, skip
        const feature_text = feature.innerHTML.trim();
        const feature_text_clean = feature_text.replace(/<[^>]*>?/g, "");
        const currentHash = yield generateHashAsync(feature_text_clean);
        // If feature hash already is equivalent, skip if it has not changed
        if (existing_features_hash_map.has(feature_id)) {
            if (existing_features_hash_map.get(feature_id) === currentHash) {
                return;
            }
        }
        // Store new hash
        existing_features_hash_map.set(feature_id, currentHash);
        // * 4. Request feature assessment
        const ASSESSMENT_URL = BASE_FEATURE_ASSESSMENT_URL.replace("placeholder_luid", block_luid).replace("placeholder_id", feature_id);
        try {
            const response = yield fetch(ASSESSMENT_URL);
            if (response.status === 404) {
                removeFeatureAssessment(feature);
                throw new Error("Feature not found");
            }
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = yield response.json();
            applyFeatureAssessment(feature, data.status, data.revision, data.description, data.timestamp);
        }
        catch (error) {
            console.warn("Feature assessment failed:", error.message);
            removeFeatureAssessment(feature);
        }
    });
}
// Fix scannable elements filter
function initialiseBlockScanner(block_luid, block_editor, csrf_token) {
    // * 1. Validate arguments
    if (!block_luid || !block_editor || !(block_editor instanceof HTMLElement) || !csrf_token) {
        console.error("Invalid block scanner parameters received!");
        return;
    }
    // * 2. Get all block scannable elements
    const scannable_elements = SCANNABLE_TAGS.flatMap((tag) => Array.from(block_editor.querySelectorAll(tag))).filter((el) => { var _a, _b; return ((_b = (_a = el.textContent) === null || _a === void 0 ? void 0 : _a.trim().length) !== null && _b !== void 0 ? _b : 0) >= MIN_FEATURE_CHARS; });
    // * 3. Track features
    const scannable_features = [];
    const scannable_feature_ids = new Set();
    // * 4. Process elements
    scannable_elements.forEach((element) => {
        if (!(element instanceof HTMLElement))
            return;
        try {
            if (element.hasAttribute(FEATURE_ATTRIBUTE)) {
                const feature_id = element.getAttribute(FEATURE_ATTRIBUTE);
                if (!feature_id)
                    return;
                if (scannable_feature_ids.has(feature_id)) {
                    const new_id = generateFeatureID(Array.from(scannable_feature_ids));
                    removeFeatureAssessment(element);
                    element.setAttribute(FEATURE_ATTRIBUTE, new_id);
                    scannable_feature_ids.add(new_id);
                }
                else {
                    scannable_feature_ids.add(feature_id);
                }
                scannable_features.push(element);
            }
            else {
                const new_id = generateFeatureID(Array.from(scannable_feature_ids));
                scannable_feature_ids.add(new_id);
                scannable_features.push(element);
                element.setAttribute(FEATURE_ATTRIBUTE, new_id);
                element.dispatchEvent(new Event("input"));
            }
        }
        catch (error) {
            console.error("Error processing feature element:", error);
        }
    });
    // * 5. Print scannable features
    console.log("Scannable features:", scannable_features);
    // * 6. Add input listeners to features
    scannable_features.forEach((feature) => {
        // verify feature is a DOM element
        if (!(feature instanceof HTMLElement)) {
            console.error("Invalid feature: must be a DOM element");
            return;
        }
        console.log("Processing feature:", feature);
        if (processed_block_features.has(feature))
            return;
        const feature_id = feature.getAttribute(FEATURE_ATTRIBUTE);
        if (!feature_id)
            return;
        FeatureQueue.push(feature);
        // ! Default case: call itself again in 15 seconds for periodic check
        setTimeout(() => {
            FeatureQueue.push(feature);
        }, 15000);
        processed_block_features.set(feature, true);
        console.log("Processed feature:", feature_id);
    });
    // * 7. Print scannable features
    console.log("Checking for scannable features in :", block_luid);
    // * 8. Only update server if we have features to track
    if (scannable_feature_ids.size > 0) {
        const URL_ENDPOINT = SCANNABLE_FEATURE_BASE_URL.replace("placeholder_luid", block_luid);
        fetch(URL_ENDPOINT, {
            method: "POST",
            headers: {
                "X-CSRFToken": csrf_token,
                "Content-Type": "application/json",
            },
            credentials: "same-origin",
            body: JSON.stringify({
                feature_ids: [...scannable_feature_ids],
            }),
        })
            .then((response) => {
            if (!response.ok) {
                throw new Error(`Server returned ${response.status}`);
            }
            return response.json();
        })
            .then((data) => console.log("Features updated:", data))
            .catch((error) => console.error("Error updating features:", error));
    }
    // * 9. Return scannable features
}
export { initialiseBlockScanner };
